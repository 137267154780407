<template>
  <v-container
    id="stores-list"
    tag="section"
    fluid
  >
    <StoreFilters @onFiltersChanged="onFiltersChanged" />
    <v-sheet
      elevation="3"
      rounded
    >
      <v-data-table
        :footer-props="table.footerProps"
        :headers="table.headers"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :page="table.options.page"
        :server-items-length="result.count"
        @update:page="load"
        @update:sort-by="load"
        @update:sort-desc="load"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot:item.short_name="{ item }">
          <v-btn
            small
            text
            target="_blank"
            :href="getStoreMarketPlaceUrl(item)"
          >
            <v-icon left>
              mdi-open-in-new
            </v-icon>{{ item.short_name }}
          </v-btn>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn
            icon
            :to="`/stores/${item.id}`"
          >
            <v-icon
              color="blue lighten-1"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.stats_products_pending_price_amount="{ item }">
          {{ item.stats_products_pending_price_amount | currency }}
        </template>
        <template v-slot:item.stripe_balance_amount="{ item }">
          {{ item.stripe_balance_amount | currency }}
        </template>
        <template v-slot:item.stripe_total_payout_amount="{ item }">
          {{ item.stripe_total_payout_amount | currency }}
        </template>
        <template v-slot:item.created_at="{ item }">
          <span :title="item.created_at | date">{{ item.created_at | dateSimple }}</span>
        </template>
        <template v-slot:item.vacancy_start_datetime="{ item }">
          <span :title="item.vacancy_start_datetime | date">{{ item.vacancy_start_datetime | dateSimple }}</span>
        </template>
        <template v-slot:item.vacancy_end_datetime="{ item }">
          <span :title="item.vacancy_end_datetime | date">{{ item.vacancy_end_datetime | dateSimple }}</span>
        </template>
        <template v-slot:item.last_authentication_date_time="{ item }">
          <span :title="item.last_authentication_date_time | date">
            {{ item.last_authentication_date_time | dateSimple }}
          </span>
        </template>
        <template v-slot:item.enabled="{ item }">
          <v-chip
            v-if="item.enabled"
            color="green"
          />
          <v-chip
            v-else
            color="red"
          />
        </template>
        <template v-slot:item.stripe_account_certified="{ item }">
          <v-chip
            v-if="item.stripe_account_certified"
            color="green"
          />
          <v-chip
            v-else
            color="red"
          />
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import { getQueryString } from '@/helpers/queryString'
import StoreFilters from '@/views/Stores/Filters'

export default {
  name: 'StoresList',
  components: { StoreFilters },
  data() {
    return {
      request: null,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: [],
          sortDesc: [],
        },
        headers: [
          {
            text: this.$i18n.t('views.stores.headers.edit'),
            value: 'edit',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.stores.headers.enabled'),
            value: 'enabled',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.stores.headers.name'),
            value: 'name',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.short_name'),
            value: 'short_name',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.stores.headers.email'),
            value: 'email',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.stats_products_pending_price_amount'),
            value: 'stats_products_pending_price_amount',
            align: 'right',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.stripe_balance_amount'),
            value: 'stripe_balance_amount',
            align: 'right',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.stripe_total_payout_amount'),
            value: 'stripe_total_payout_amount',
            align: 'right',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.created_at'),
            value: 'created_at',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.vacancy_start_datetime'),
            value: 'vacancy_start_datetime',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.vacancy_end_datetime'),
            value: 'vacancy_end_datetime',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.stripe_account_id'),
            value: 'stripe_account_id',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.stores.headers.stripe_account_certified'),
            value: 'stripe_account_certified',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.stores.headers.last_authentication_date_time'),
            value: 'last_authentication_date_time',
            sortable: true,
          },
        ],
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        name: this.$route.query.name,
        email: this.$route.query.email,
        enabled: this.$route.query.enabled,
        kind: this.$route.query.kind,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'stores?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'StoresList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'StoresList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    getStoreMarketPlaceUrl(store) {
      return process.env.VUE_APP_MP_BASE_URL + store.marketplace_url
    },
  },
}
</script>

<style scoped>
</style>
